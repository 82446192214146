:root {
  --app-height: 100%;
}

.player-view .player .name {
  font-weight: 700;
  font-size: medium;
}
@media (orientation: landscape) {
  .pitch {
    background-color: green;
    padding-bottom: 58.3%;
    width: 100%;
  }
}

/* Styles for portrait orientation */
@media (orientation: portrait) {
  .pitch {
    background-color: green;
    padding-bottom: 150%; /* Adjust the aspect ratio for portrait */
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .player-view .player .name {
    font-weight: 700;
    font-size: medium;
    position: absolute;
    bottom: -100% !important;
    width: max-content;
  }
}
